import './NavBarBottom.css';

function NavBarBottom() {
  return (
  	<div className="navbar-bottom">
  		<HomeIcon />
  		<AccountIcon />
	</div>
  );
}

function HomeIcon(){
	return (
		<div className="home-icon">
			<HomeSVG />
		</div>
	)
}

function HomeSVG(){
	return (
		<>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.0582 11.7246L12.5945 5.94451C12.2492 5.67694 11.7505 5.67694 11.405 5.94451L3.94129 11.7246C3.72594 11.8914 3.60132 12.1395 3.60132 12.4014V21.1225C3.60132 21.6072 4.01968 22 4.536 22H19.4633C19.9796 22 20.398 21.6072 20.398 21.1225V12.4014C20.3982 12.1395 20.2736 11.8914 20.0582 11.7246ZM12.9881 20.2451H11.0116V15.3871H12.988V20.2451H12.9881ZM18.5289 20.2451H14.8574V14.5097C14.8574 14.025 14.4391 13.6322 13.9228 13.6322H10.0769C9.56057 13.6322 9.14221 14.025 9.14221 14.5097V20.2451H5.4708V12.8151L11.9998 7.75883L18.5289 12.8151V20.2451Z" fill="#817F7E"/>
			<path d="M22.6496 9.96439C20.5676 8.39879 18.6046 6.90176 16.4731 5.25415C15.8364 4.71704 15.1672 4.20847 14.5196 3.7164C13.8857 3.23462 13.23 2.73635 12.6252 2.22533C12.2696 1.92489 11.7302 1.92489 11.3746 2.22533C10.7697 2.73635 10.1142 3.23462 9.48024 3.7164C8.83282 4.20835 8.16359 4.71704 7.52676 5.25415C5.39545 6.90187 3.43238 8.39879 1.35042 9.96439C0.947508 10.2673 0.882454 10.8196 1.20523 11.1977C1.52788 11.5758 2.11635 11.637 2.51901 11.334C4.61294 9.75965 6.58673 8.25431 8.73087 6.59664C8.74147 6.58857 8.75181 6.58014 8.76203 6.57149C9.36695 6.06046 10.0225 5.56219 10.6564 5.08041C11.1 4.74348 11.5536 4.3987 12 4.04234C12.4464 4.3987 12.9002 4.74348 13.3436 5.08041C13.9775 5.56219 14.6332 6.06046 15.238 6.57149C15.2482 6.58014 15.2585 6.58857 15.2691 6.59664C17.4132 8.25431 19.3871 9.75965 21.481 11.334C21.6535 11.4636 21.8597 11.5266 22.0647 11.5266C22.3386 11.5266 22.6102 11.414 22.7948 11.1976C23.1175 10.8196 23.0525 10.2673 22.6496 9.96439Z" fill="#817F7E"/>
			</svg>
		</>
	)
}

function AccountIcon(){
	return (
		<div className="account-icon">
			<AccountSVG />
		</div>
	)
}

function AccountSVG(){
	return (
		<>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.745 20.745C5.308 19.722 2 15.747 2 11C2 5.477 6.477 1 12 1C17.523 1 22 5.477 22 11C22 15.747 18.692 19.722 14.255 20.745L12 23L9.745 20.745V20.745ZM7.012 17.257C7.9438 18.0022 9.03132 18.5282 10.194 18.796L10.754 18.925L12 20.172L13.247 18.925L13.807 18.795C15.0456 18.5095 16.1978 17.9313 17.167 17.109C16.5158 16.4406 15.7372 15.9095 14.8772 15.5473C14.0171 15.185 13.0932 14.9989 12.16 15C10.124 15 8.29 15.87 7.012 17.257V17.257ZM5.616 15.82C6.45645 14.9281 7.47067 14.2177 8.59614 13.7327C9.72161 13.2477 10.9345 12.9984 12.16 13C13.3417 12.9985 14.512 13.2304 15.6037 13.6824C16.6955 14.1344 17.6873 14.7976 18.522 15.634C19.3781 14.4291 19.8836 13.0106 19.9826 11.5359C20.0815 10.0612 19.77 8.58789 19.0825 7.27946C18.395 5.97102 17.3585 4.87862 16.088 4.12345C14.8174 3.36827 13.3625 2.97984 11.8846 3.00125C10.4067 3.02267 8.96366 3.45308 7.71552 4.24476C6.46738 5.03643 5.46296 6.1584 4.81369 7.4862C4.16442 8.814 3.89569 10.2957 4.03732 11.7669C4.17894 13.2382 4.72537 14.6414 5.616 15.821V15.82ZM12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12ZM12 10C12.5304 10 13.0391 9.78929 13.4142 9.41421C13.7893 9.03914 14 8.53043 14 8C14 7.46957 13.7893 6.96086 13.4142 6.58579C13.0391 6.21071 12.5304 6 12 6C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8C10 8.53043 10.2107 9.03914 10.5858 9.41421C10.9609 9.78929 11.4696 10 12 10Z" fill="#817F7E"/>
			</svg>
		</>
	)
}

export default NavBarBottom;
import logo from './logo.svg';
import './App.css';
import MenuApp from '@cado/self-serve-tablet/src/App';
import NavBarBottom from './NavBarBottom';
import RestaurantCard from './RestaurantCard';
import React, { useState, useEffect } from 'react';
import { APIURL } from './constants';

function App() {
  // let restaurantCardData = {
  //   "restaurantTitle": "Restaurant name",
  //   "status": "Open",
  //   "distanceAway": "1.2 miles",
  //   "foodType": "Mexican",
  //   "reviewInfo": "4.6",
  //   "imageUrl": "https://cado-assets.s3.amazonaws.com/Rectangle+1.png",
  // }
  const [restaurantsData, setRestaurantsData] = useState(null);

  const load = async () => {
    let url = new URL(APIURL + '/get_restaurants_basic_info');
    let options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    const restaurantsDataTmp = await fetch(url, options).then((res) => res.json());
    setRestaurantsData(restaurantsDataTmp)
  };

  useEffect(() => {
    load();
  }, []);

  let restaurantCards = null;
  if(restaurantsData !== null && restaurantsData["basicInfo"] !== null){
  	console.log("restaurantsData: " + JSON.stringify(restaurantsData))
    restaurantCards = restaurantsData["basicInfo"].map((restaurantCardData) => {
      return <RestaurantCard restaurantCardData={restaurantCardData} />
    });
  }

  return (
    <div className="App">
      <div className="content">
        <div className="restaurant-cards">
          {restaurantCards}
{/*          <RestaurantCard restaurantCardData={restaurantCardData} />
          <RestaurantCard restaurantCardData={restaurantCardData} />
          <RestaurantCard restaurantCardData={restaurantCardData} />
          <RestaurantCard restaurantCardData={restaurantCardData} />
          <RestaurantCard restaurantCardData={restaurantCardData} />
          <RestaurantCard restaurantCardData={restaurantCardData} />*/}
        </div>
      </div>
      <NavBarBottom />
    </div>
  );
}

export default App;
